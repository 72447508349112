<template>
  <div>
    <form-create
      v-if="isAddNewSidebarActive"
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :lista-empresas="listaEmpresas"
      titulo="Novo Projeto"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">Lista de Projetos</h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="9">
            <div class="row">
              <div class="col-5">
                <select-com-pesquisa
                  ref="selectTimes"
                  :itens-para-edicao="[]"
                  :multiple="true"
                  placeholder="Filtre por disciplinas"
                  valor-name-select="abreviacao"
                  url="/disciplinas"
                  @selecionados="tratarEventoFiltroDisciplinas"
                />
              </div>
              <div class="col-5">
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Pesquisar..."
                />
              </div>
              <div class="col-2">
                <b-button
                  v-if="$can('salvar', 'projetos')"
                  variant="success"
                  @click="isAddNewSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(descricao)="data">
          <div class="column__w250">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="4.5em"
                  :src="data.item.cliente.imagem"
                  :text="avatarText(data.item.cliente.razao_social)"
                  :variant="'secondary'"
                  class="avatar-with-shadow"
                />
              </template>
              <div>
                <small>{{ data.item.descricao }}</small>
              </div>
              <div class="font-size-8 text-primary">
                <strong>Atualizado em: </strong
                >{{ data.item.updated_at | formatDataHora }}
              </div>
            </b-media>
          </div>
        </template>

        <template #cell(disciplinas)="data">
          <div class="displinasProjetos">
            <tag-color
              v-for="(item, index) in data.item.disciplinas"
              :key="index"
              v-b-tooltip.hover.top.v-primary
              class="mb-1 cursor"
              :texto="item.abreviacao"
              :title="item.nome"
              :bg-color="item.bg_color"
            />
          </div>
        </template>

        <template #cell(data_entrega)="data">
          <div>
            <small>{{ data.item.data_entrega | formatDate }}</small>
          </div>
        </template>
        <template #cell(participantes)="data">
          <popover-integrantes
            v-if="ehTimes(data.item)"
            :itens="data.item.times"
            :prefix="data.item.uuid"
            :badget-group="{ text: 'T', variant: 'primary' }"
          />
          <popover-integrantes
            v-else
            :itens="data.item.participantes"
            :prefix="data.item.uuid"
            :badget-group="{ text: 'P', variant: 'primary' }"
          />
          <popover-integrantes
            :itens="data.item.users"
            :popover-disabled="true"
            :prefix="data.item.uuid"
            :badget-group="{ text: 'R', variant: 'secondary' }"
          />
        </template>
        <template #cell(formato)="data">
          {{ data.item.formato && data.item.formato.abreviacao }}
        </template>
        <template #cell(realizado)="data">
          <div class="d-flex justify-content-center">
            {{ data.item.tarefas_concluidas_count }} de
            {{ data.item.tarefas_count }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <a v-if="$can('atualizar', 'projetos')">
            <feather-icon icon="EditIcon" @click="editarItem(data.item)" />
            <span class="align-middle ml-50" />
          </a>
          <b-dropdown
            v-if="
              $can('atualizar', 'projetos') || $can('atualizar', 'comentarios')
            "
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            class="column__w50"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('atualizar', 'projetos')"
              :to="{
                name: 'projetos-checklists',
                params: { id: data.item.uuid },
              }"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Checklists</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('atualizar', 'comentarios')"
              :to="{
                name: 'comentarios',
                params: { id: data.item.uuid, modulo: 'projetos' },
              }"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle ml-50">Comentários</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('excluir', 'projetos')"
              @click="modalExcluir(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('atualizar', 'projetos')"
              :to="{
                name: 'projeto-localizacoes',
                params: { id: data.item.uuid },
              }"
            >
              <feather-icon icon="MapPinIcon" />
              <span class="align-middle ml-50">Localização</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos
              {{ dataMeta.of }} resultados encontrados</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="my-1"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <modalConfirmacao
      id="modal-confirmacao"
      titulo="Excluir Item"
      :item-id="dadosItem.uuid"
      :item-nome="dadosItem.descricao"
      @itemConfirmado="excluirItem"
      @click="modalExcluir(data.item)"
    />

    <b-modal
      id="modal-editar"
      v-model="modalShow"
      :title="`Editar ${dadosItem.descricao}`"
      :no-close-on-backdrop="true"
    >
      <validation-provider
        #default="validationContext"
        name="Nome do Projeto"
        rules="required"
      >
        <b-form-group label="Nome do Projeto" label-for="nome-projeto">
          <b-form-input
            id="nome-projeto"
            v-model="dadosItem.descricao"
            name="projeto"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            placeholder="Informe o nome do projeto"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="validationContext"
        name="Ano do Projeto"
        rules="required|integer|digits:4|between:2000,2050"
      >
        <b-form-group label="Ano do Projeto" label-for="ano-projeto">
          <b-form-input
            id="ano-projeto"
            v-model="dadosItem.ano"
            name="ano"
            maxlength="4"
            :state="getValidationState(validationContext)"
            trim
            placeholder="Informe o ano do projeto"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Data Entrega"
        rules="required"
      >
        <b-form-group label="Data Entrega" label-for="data_entrega">
          <b-form-input
            id="data_entrega"
            v-model="dadosItem.data_entrega"
            type="date"
            :state="getValidationState(validationContext)"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="validationContext"
        name="Formato"
        rules="required"
      >
        <b-form-group label="Formato" label-for="formato">
          <select-com-pesquisa
            id="formato"
            ref="formato"
            v-model="dadosItem.formato"
            valor-name-select="nome"
            :url="`/projeto-formatos`"
            placeholder="Escolha o formato"
            :multiple="false"
            :disabled="false"
            :itens-para-edicao="[dadosItem.formato]"
            @selecionados="tratarEventoSelecionarFormato"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="validationContext"
        name="Responsáveis"
        rules="required"
      >
        <b-form-group
          label="Responsável"
          label-for="responsavel"
          :state="getValidationState(validationContext)"
        >
          <select-com-pesquisa
            id="responsavel"
            ref="responsaveis"
            :url="`/users?role=Líder`"
            placeholder="Escolha os responsáveis"
            :multiple="true"
            :disabled="false"
            :itens-para-edicao="dadosItem.users"
            @selecionados="tratarEventoResponsaveisSelecionados"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group>
        <b-row class="m-0">
          <b-form-radio
            v-model="tipoGrupo"
            class="mr-2"
            name="some-radios"
            value="participantes"
          >
            Participantes
          </b-form-radio>
          <b-form-radio v-model="tipoGrupo" name="some-radios" value="times">
            Times
          </b-form-radio>
        </b-row>
      </b-form-group>

      <validation-provider
        v-if="exibirParticipantes"
        #default="validationContext"
        name="Participantes"
        rules="required"
      >
        <b-form-group
          label="Participantes"
          label-for="participantes"
          :state="getValidationState(validationContext)"
        >
          <select-com-pesquisa
            id="participantes"
            ref="participantes"
            :url="`/users?role=Líder`"
            placeholder="Escolha os participantes"
            :multiple="true"
            :disabled="false"
            :itens-para-edicao="dadosItem.participantes"
            @selecionados="tratarEventoParticipantesSelecionados"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-else
        #default="validationContext"
        name="Times"
        rules="required"
      >
        <b-form-group
          label="Times"
          label-for="times"
          :state="getValidationState(validationContext)"
        >
          <select-com-pesquisa
            id="times"
            ref="times"
            :url="`/times`"
            placeholder="Escolha os times"
            :multiple="true"
            :disabled="false"
            :itens-para-edicao="dadosItem.times"
            @selecionados="tratarEventoTimesSelecionados"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Empresa"
        rules="required"
      >
        <b-form-group
          label="Empresa"
          label-for="empresa"
          :state="getValidationState(validationContext)"
        >
          <v-select
            id="empresa"
            v-model="dadosItem.empresa"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="listaEmpresas"
            label="nome"
            :reduce="(val) => val.uuid"
            :clearable="false"
            input-id="user-empresa"
            placeholder="Escolha uma empresa"
            multiple
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group label="Escolha o cliente" label-for="cliente">
        <select-com-pesquisa
          id="cliente"
          url="/clientes"
          placeholder="Escolha o cliente"
          :multiple="false"
          :itens-para-edicao="[dadosItem.cliente]"
          @selecionados="tratarEventoClienteSelecionados"
        />
      </b-form-group>
      <b-form-group label="Escolha as disciplinas" label-for="disciplinas">
        <select-com-pesquisa
          id="disciplinas"
          url="/disciplinas"
          placeholder="Escolha as disciplinas"
          :multiple="true"
          :itens-para-edicao="dadosItem.disciplinas"
          @selecionados="tratarEventoDisciplinasSelecionadas"
        />
      </b-form-group>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-left"
            @click="modalShow = false"
          >
            Fechar
          </b-button>
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="salvarItemEditado(dadosItem)"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import PopoverIntegrantes from '@/views/components/componentesPensou/PopoverIntegrantes.vue'
import projetosStoreModule from '@/views/apps/projetos/projetosStoreModule.js'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'
import useItemList from './useItemList'
import vSelect from 'vue-select'
import FormCreate from './FormCreate.vue'

export default {
  components: {
    PopoverIntegrantes,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormRadio,
    vSelect,
    SelectComPesquisa,
    ValidationProvider,
    ValidationObserver,
    TagColor,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const blankUserData = {
      descricao: '',
      times: null,
      users: null,
      empresa: null,
      data_entrega: null,
      ano: null,
      cliente: null,
    }

    const PROJETOS_APP_STORE_MODULE_NAME = 'app-projetos'

    // Register module
    if (!store.hasModule(PROJETOS_APP_STORE_MODULE_NAME))
      store.registerModule(PROJETOS_APP_STORE_MODULE_NAME, projetosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJETOS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROJETOS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)

    const listaEmpresas = ref([])

    store.dispatch('app-projetos/listaEmpresas').then((response) => {
      listaEmpresas.value = response.data
    })

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      disciplinasFilter,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      update,
    } = useItemList()

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetItemData)

    const filtroDisciplinas = ref([])

    return {
      isAddNewSidebarActive,
      filtroDisciplinas,

      deleteItem,
      fetchDados,
      update,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      disciplinasFilter,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,
      listaEmpresas,
      avatarText,
    }
  },
  filters: {
    formatDate(value) {
      const date = new Date(value)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
      return date.toLocaleDateString('pt-BR', options)
    },
    formatDataHora(value) {
      const date = new Date(value)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('pt-BR', options)
    },
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        descricao: '',
        data_entrega: '',
        cliente: '',
        empresa: '',
        users: [],
        times: [],
        participantes: [],
        disciplinas: [],
        ano: '',
        formato: [],
      },
      modalShow: false,
      tipoGrupo: null,
    }
  },
  computed: {
    exibirParticipantes() {
      return this.tipoGrupo === 'participantes'
    },
    isLoad() {
      return this.$store.state['app-projetos']?.load
    },
    items() {
      return this.fetchDados
    },
  },
  watch: {
    tipoGrupo(newValue) {
      if (newValue === 'participantes') {
        this.dadosItem.times = []
      } else {
        this.dadosItem.participantes = []
      }
    },
  },
  methods: {
    tratarEventoFiltroDisciplinas(itens) {
      this.disciplinasFilter = itens.map((item) => item.id)
    },

    editarItem(item) {
      this.modalShow = true
      this.tipoGrupo = item.participantes.length > 0 ? 'participantes' : 'times'

      this.dadosItem = {
        ...item,
        data_entrega: this.formatarDataEdicao(item.data_entrega),
        empresa: item.empresas.map((item) => item.uuid),
        users: item.users,
        times: item.times,
        participantes: item.participantes,
      }
    },
    ehTimes(item) {
      return item.times.length > 0
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
    modalExcluir(item) {
      this.dadosItem.descricao = item.descricao
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    salvarItemEditado(item) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')
      this.update(item)
    },
    tratarEventoClienteSelecionados(item) {
      this.dadosItem.cliente = item.id
    },
    tratarEventoDisciplinasSelecionadas(itens) {
      this.dadosItem.disciplinas = itens
    },
    formatarDataEdicao(dateString) {
      const dateParts = dateString.split('T')[0].split('-')
      const year = parseInt(dateParts[0])
      const month = parseInt(dateParts[1])
      const day = parseInt(dateParts[2])
      return `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`
    },
    tratarEventoResponsaveisSelecionados(itens) {
      this.dadosItem.users = itens || {}
    },
    tratarEventoSelecionarFormato(item) {
      this.dadosItem.formato = item || {}
    },
    tratarEventoParticipantesSelecionados(itens) {
      this.dadosItem.participantes = itens || {}
    },
    tratarEventoTimesSelecionados(itens) {
      this.dadosItem.times = itens || {}
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.displinasProjetos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 160px;
}
</style>
